import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import pt from './translations/pt';
import en from './translations/en';
import es from './translations/es';
import nl from './translations/nl';

const resources = {
  pt: { translation: pt }, en: { translation: en }, es: { translation: es }, nl: { translation: nl }
}

const getDefaultLangByHost = () => {
  const host = window.location.host;
  const englishDomains = (process.env.REACT_APP_DEFAULT_ENGLISH_DOMAINS||'').split(',');
  for(let domain of englishDomains){
    if(domain.trim().length === 0) continue;
    if(host.indexOf(domain) > -1){
      return 'en';
    }
  }

  return 'pt';
}

export const getSavedLang = () => {
  const lang = localStorage.getItem('language');
  switch (lang) {
    case 'pt':
    case 'en':
    case 'es':
    case 'nl':
      return lang;
    default:
      return getDefaultLangByHost();
  }
}

export const saveLang = (lang) => {
  switch (lang) {
    case 'pt':
    case 'en':
    case 'es':
    case 'nl':
      localStorage.setItem('language', lang);
      return true;
    default:
      return false;
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getSavedLang(),
    keySeparator: true
  })

export default i18n;
