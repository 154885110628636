import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import './utils/string';

import './i18n';

import * as serviceWorker from './serviceWorker';


const ENVIRONMENTS = [
  {
    name: "selfregistration",
    path: "./App.js",
  },
  {
    name: "seatreservation",
    path: "./AppSeatReservation.js",
  },
  {
    name: "qrcode",
    path: "./AppQRCodeGenerator.js",
  },
  {
    name: "d2d",
    path: "./AppD2D.js",
  },
  {
    name: "dyntrip",
    path: "./AppDynTrip.js",
  },
];

// Determine which entry point to import
const { path } = ENVIRONMENTS.find(
  ({ name }) => process.env.REACT_APP_BUILD_TARGET === name
);

// Import the entry point and render it's default export
import(`${path}`).then(({ default: Environment }) =>
  ReactDOM.render(
    <React.StrictMode>
      <Environment />
    </React.StrictMode>,
    document.getElementById("root")
  )
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
