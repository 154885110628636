export default {
  hello: "Hello",
  "app.title": "Company Transportation",
  "steps.company.verification.checking": "Verifying by",
  "steps.company.verification.fail": "Unable to proceed",
  "steps.rider.name.title": "What is your name?",
  "steps.rider.name.placeholder": "First and last name",
  "steps.rider.email.title": "What is your corporate email?",
  "steps.rider.email.placeholder": "me@mycompany.com",
  "steps.rider.code.title": "Enter your",
  "steps.rider.verification.checking.title": "Requesting access for",
  "steps.rider.verification.fail.title": "Unable to process your request",
  "steps.tripschedule.overview.fail.title":
    "Unable to retrieve Service information",
  "steps.tripschedule.overview.rider.greetings": "Hello, ",
  "steps.tripschedule.overview.trip.status": "Your trip is",
  "steps.tripschedule.overview.trip.planned": "scheduled",
  "steps.tripschedule.overview.trip.on_going": "in progress",
  "steps.tripschedule.overview.trip.ended": "completed",
  "steps.tripschedule.overview.trip.embark": "Embark",
  "steps.tripschedule.overview.trip.destination": "Destination",
  "steps.tripschedule.overview.trip.scheduled_date": "Journey on",
  "steps.tripschedule.overview.trip.solicitation_number": "Service",
  "steps.tripschedule.overview.trip.last_checked_at": "Updated at",
  "steps.tripschedule.overview.trip.see_on_map": "View on map",
  "steps.tripschedule.overview.trip.your_driver": "Your driver",
  "steps.tripschedule.onmap.navbar.status.scheduled": "Scheduled",
  "steps.tripschedule.onmap.navbar.title": "SERVICE",
  "steps.tripschedule.onmap.driver.phone": "Phone",
  "steps.tripschedule.onmap.driver.phone.copied": "Phone copied to clipboard",
  "steps.tripschedule.onmap.driver.phone.clicktocopy":
    "Click to copy phone number",
  "steps.tripschedule.onmap.navbar.status.on_going": "In progress",
  "steps.tripschedule.onmap.navbar.status.planned": "Scheduled",
  "steps.tripschedule.onmap.navbar.status.ended": "Completed",
  "steps.tripschedule.onmap.alerts.fail.refresh": "Location failed",
  "steps.tripschedule.onmap.alerts.fail.location":
    "Unable to retrieve real-time location.",
  "steps.tripschedule.onmap.alerts.fail.token":
    "Unable to retrieve real-time location (auth).",
  "buttons.start": "Start",
  "buttons.continue": "Continue",
  "buttons.tryagain": "Try again",
  "errors.InvalidQRCodeRequestException":
    "Your registration was not found. Please check your details and try again.",
  "errors.UnknownException": "Unknown error",
  "errors.ServerException": "Communication error",
  "errors.InvalidCompanyCodeException": "Company not found",
  "errors.RiderCodeAlreadyExists":
    "A rider with your details is already registered",
  "errors.InvalidRegistrationRequestException": "Your details are not valid",
  "errors.RequestAlreadyExistsException": "You already have a pending request",
  "code_type.EMPLOYEE_CODE": "employee ID",
  "code_type.USER_CODE": "user code",
  "code_type.PHONE": "phone",

  "seat.login.title": "Seat Reservation",
  "seat.login.input.company": "Company code",
  "seat.login.input.internalCode": "Rider code",
  "seat.login.button": "Login",
  "seat.login.changeLanguage": "Change language",
  "seat.login.needHelp.pre": "Need help?",
  "seat.login.needHelp.link": "Touch here for customer support.",
  "seat.login.error": "Invalid company code or rider code.",
  "seat.login.warning": "Type your data correctly.",
  "seat.profile.title": "Profile",
  "seat.profile.logout": "Logout",
  "seat.profile.language": "Language",
  "seat.profile.language.change": "(change)",
  "seat.languageSelector.title": "Language",
  "seat.languageSelector.instruction": "Select your language",
  "seat.home.error.pre": "Something wrong has happened. Try again or ",
  "seat.home.error.link": "touch here for customer support",
  "seat.home.wait": "Wait",
  "seat.home.pickup": "Pickup",
  "seat.home.departure": "Departure",
  "seat.home.dropoff": "Dropoff",
  "seat.home.nextWindowOpensAt":
    "Seat reservation for next week will start at ",
  "seat.home.close": "Close",
  "seat.home.customerSupportLink": "Cant see your routes?",
  "seat.home.reducedCapacityWarning":
    "As a health measures against COVID-19, we are operating with reduced occupancy.",
  "seat.home.search": "Search by name",
  "seat.component.week.title": "Week",
  "seat.component.week.to": "to",
  "seat.component.listReservation.available": "Available",
  "seat.component.listReservation.reserved": "Reserved",
  "seat.component.listReservation.blocked": "Blocked",
  "seat.component.listReservation.empty.1": "No routes",
  "seat.component.listReservation.empty.2": "available for this date",
  "seat.component.listReservation.notReserved": "Not reserved",
  "seat.component.listReservation.availableSeats": "available seats",
  "seat.component.listReservation.noAvailableSeats": "No available seats",
  "seat.component.listReservation.reservationUntil": "Book until {0} today",
  "seat.component.listReservation.reservationUntil.past": "Closed at {0}",
  "seat.component.listReservation.reservationAvailable":
    "Available for reservation from {0} to {1}",
  "seat.component.listReservation.reservationAvailableDate":
    "Available for reservation on {0}",
  "seat.component.modal.error.title": "There is no more seat available for",
  "seat.component.modal.error.text":
    "If you still need transport on this date, please contact the Logistics Operator that serves your region for a possible conciliation.",
  "seat.component.modal.close": "Close",
  "seat.component.modal.date.1": "at",
  "seat.component.modal.date.2": "th",
  "seat.component.modal.confirm.title": "Confirm your reservation to",
  "seat.component.modal.confirm.action": "Yes, reserve",
  "seat.component.modal.cancel": "Cancel",
  "seat.component.modal.cancel.title": "Confirm cancellation to",
  "seat.component.modal.cancel.text": "Yes, cancel",
  "seat.component.modal.quit.text": "Sim, quit",
  "seat.component.modal.cancel.abort": "No, back",
  "seat.faq.empty.title": "No FAQ available",
  "seat.faq.empty.body": "For customer support please contact your company.",
  "seat.stops.input": "Search stop",
  "seat.stops.embark": "Your embark",
  "seat.stops.address": "Your address",
  "seat.stops.steps.stops": "Stops",
  "seat.stops.steps.confirmation": "Confirmation",
  "seat.stops.title": "Choose a stop",
  "seat.stops.confirmation.title": "Confirm your reservation",
  "seat.stops.confirmation.confirm": "Confirm reservation",
  "seat.stops.modal.lastReservation.confirm": "Repeat reservation",
  "seat.stops.modal.lastReservation.cancel": "Choose another stop",
  "seat.stops.modal.lastReservation.title": "Last reservation on this route",
  "seat.stops.modal.confirm.cancel":
    "By proceeding, the reservation will not be completed",
  "seat.home.myRoutes": "See my routes only",
  "dyn.trip.IN": "IN",
  "dyn.trip.OUT": "OUT",
};
