export default {
  "app.title": "Bedrijfstransport",
  "steps.company.verification.checking": "Controleren door",
  "steps.company.verification.fail": "Kan niet doorgaan",
  "steps.rider.name.title": "Wat is je naam?",
  "steps.rider.name.placeholder": "Voor- en achternaam",
  "steps.rider.email.title": "Wat is je zakelijke e-mail?",
  "steps.rider.email.placeholder": "ik@mijnbedrijf.com.br",
  "steps.rider.code.title": "Voer je",
  "steps.rider.verification.checking.title": "Toegang aanvragen voor",
  "steps.rider.verification.fail.title": "Je aanvraag kon niet worden voltooid",
  "steps.tripschedule.overview.fail.title": "Kon de informatie niet verkrijgen",
  "steps.tripschedule.overview.rider.greetings": "Hallo, ",
  "steps.tripschedule.overview.trip.status": "Je reis is",
  "steps.tripschedule.overview.trip.planned": "gepland",
  "steps.tripschedule.overview.trip.on_going": "bezig",
  "steps.tripschedule.overview.trip.ended": "beëindigd",
  "steps.tripschedule.overview.trip.embark": "Instappen",
  "steps.tripschedule.overview.trip.destination": "Bestemming",
  "steps.tripschedule.overview.trip.scheduled_date": "Reis op ",
  "steps.tripschedule.overview.trip.solicitation_number": "Aanvraag",
  "steps.tripschedule.overview.trip.last_checked_at": "Bijgewerkt om ",
  "steps.tripschedule.overview.trip.see_on_map": "Bekijk op de kaart",
  "steps.tripschedule.overview.trip.your_driver": "Je chauffeur",
  "steps.tripschedule.onmap.navbar.status.scheduled": "Gepland",
  "steps.tripschedule.onmap.navbar.title": "AANVRAAG",
  "steps.tripschedule.onmap.driver.phone": "Telefoon",
  "steps.tripschedule.onmap.driver.phone.copied":
    "Telefoon gekopieerd naar klembord",
  "steps.tripschedule.onmap.driver.phone.clicktocopy":
    "Klik om telefoonnummer te kopiëren",
  "steps.tripschedule.onmap.navbar.status.on_going": "Bezig",
  "steps.tripschedule.onmap.navbar.status.planned": "Gepland",
  "steps.tripschedule.onmap.navbar.status.ended": "Voltooid",
  "steps.tripschedule.onmap.alerts.fail.refresh": "Locatiefout",
  "steps.tripschedule.onmap.alerts.fail.location":
    "Kon de realtime locatie niet verkrijgen.",
  "steps.tripschedule.onmap.alerts.fail.token":
    "Kon de realtime locatie niet verkrijgen (auth).",
  "buttons.start": "Beginnen",
  "buttons.continue": "Doorgaan",
  "buttons.tryagain": "Probeer opnieuw",
  "errors.InvalidQRCodeRequestException":
    "Je registratie kon niet worden gevonden. Controleer je gegevens en probeer het opnieuw.",
  "errors.UnknownException": "Onbekende fout",
  "errors.ServerException": "Communicatiefout",
  "errors.InvalidCompanyCodeException": "Bedrijf niet gevonden",
  "errors.RiderCodeAlreadyExists":
    "Er is al een passagier geregistreerd met jouw gegevens",
  "errors.InvalidRegistrationRequestException": "Je gegevens zijn niet geldig",
  "errors.RequestAlreadyExistsException": "Je hebt al een lopende aanvraag",
  "code_type.EMPLOYEE_CODE": "registratienummer",
  "code_type.USER_CODE": "gebruikerscode",
  "code_type.PHONE": "telefoon",
  "seat.login.title": "Stoelreservering",
  "seat.login.input.company": "Bedrijfscode",
  "seat.login.input.internalCode": "Gebruikerscode",
  "seat.login.button": "Inloggen",
  "seat.login.changeLanguage": "Taal wijzigen",
  "seat.login.needHelp.pre": "Hulp nodig?",
  "seat.login.needHelp.link": "Klik hier voor ondersteuning.",
  "seat.login.error": "Ongeldige bedrijfs- of gebruikerscode.",
  "seat.login.warning": "Voer de gegevens correct in.",
  "seat.profile.title": "Profiel",
  "seat.profile.logout": "Uitloggen",
  "seat.profile.language": "Taal",
  "seat.profile.language.change": "(wijzigen)",
  "seat.languageSelector.title": "Taal",
  "seat.languageSelector.instruction": "Kies een taal",
  "seat.home.error.pre": "Er is iets misgegaan. Probeer het opnieuw of ",
  "seat.home.error.link": "klik hier en neem contact op met de ondersteuning",
  "seat.home.wait": "Even geduld",
  "seat.home.pickup": "Inscheping",
  "seat.home.departure": "Vertrek",
  "seat.home.dropoff": "Ontscheping",
  "seat.home.nextWindowOpensAt":
    "Reserveringen voor volgende week zijn beschikbaar vanaf ",
  "seat.home.close": "Sluiten",
  "seat.home.customerSupportLink": "Zie je de lijnen die je nodig hebt niet?",
  "seat.home.reducedCapacityWarning":
    "Als een maatregel tegen COVID-19 werken we met verminderde capaciteit.",
  "seat.home.search": "Zoek op naam",
  "seat.component.week.title": "Week",
  "seat.component.week.to": "tot",
  "seat.component.listReservation.available": "Beschikbaar",
  "seat.component.listReservation.reserved": "Gereserveerd",
  "seat.component.listReservation.blocked": "Geblokkeerd",
  "seat.component.listReservation.empty.1": "Geen route",
  "seat.component.listReservation.empty.2": "beschikbaar op deze dag",
  "seat.component.listReservation.notReserved": "Niet gereserveerd",
  "seat.component.listReservation.availableSeats": "beschikbare stoelen",
  "seat.component.listReservation.noAvailableSeats": "Geen beschikbare stoelen",
  "seat.component.listReservation.reservationUntil":
    "Reserveren tot {0} vandaag",
  "seat.component.listReservation.reservationUntil.past": "Gesloten om {0}",
  "seat.component.modal.error.title":
    "Het lijkt erop dat de stoelen net zijn uitverkocht voor",
  "seat.component.listReservation.reservationAvailable":
    "Beschikbaar voor reservering van {0} tot {1}",
  "seat.component.listReservation.reservationAvailableDate":
    "Beschikbaar voor boeking op {0}",
  "seat.component.modal.error.text":
    "Als je op deze datum nog vervoer nodig hebt, neem dan contact op met de logistieke operator die jouw regio bedient voor een mogelijke bemiddeling.",
  "seat.component.modal.close": "Sluiten",
  "seat.component.modal.date.1": "op",
  "seat.component.modal.date.2": " van",
  "seat.component.modal.date": "op",
  "seat.component.modal.confirm.title": "Bevestig reservering voor",
  "seat.component.modal.confirm.action": "Ja, reserveren",
  "seat.component.modal.cancel": "Annuleren",
  "seat.component.modal.cancel.title": "Bevestig annulering voor",
  "seat.component.modal.cancel.text": "Ja, annuleren",
  "seat.component.modal.quit.text": "Ja, geef het op",
  "seat.component.modal.cancel.abort": "Nee, teruggaan",
  "seat.faq.empty.title": "Geen vragen beschikbaar",
  "seat.faq.empty.body": "Neem bij vragen contact op met je bedrijf.",
  "seat.stops.input": "Zoek een stop",
  "seat.stops.embark": "Je inscheping",
  "seat.stops.steps.stops": "Stopt",
  "seat.stops.steps.confirmation": "Bevestiging",
  "seat.stops.title": "Kies een stop",
  "seat.stops.confirmation.title": "Bevestig uw reservering",
  "seat.stops.confirmation.confirm": "Bevestig reservering",
  "seat.stops.modal.lastReservation.confirm": "Herhaal reservering",
  "seat.stops.modal.lastReservation.cancel": "Kies een andere stop",
  "seat.stops.modal.lastReservation.title": "Laatste reservering op deze route",
  "seat.stops.modal.confirm.cancel":
    "Door te gaan, wordt de reservering niet voltooid",
  "seat.home.myRoutes": "Bekijk alleen mijn routes",
  "dyn.trip.IN": "HEEN",
  "dyn.trip.OUT": "TERUG",
};
