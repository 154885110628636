export default {
  "app.title": "Transporte da Empresa",
  "steps.company.verification.checking": "Verificando por",
  "steps.company.verification.fail": "Não é possível continuar",
  "steps.rider.name.title": "Qual o seu nome?",
  "steps.rider.name.placeholder": "Nome e sobrenome",
  "steps.rider.email.title": "Qual o seu e-mail corporativo?",
  "steps.rider.email.placeholder": "eu@minhaempresa.com.br",
  "steps.rider.code.title": "Digite sua",
  "steps.rider.verification.checking.title": "Solicitando acesso para",
  "steps.rider.verification.fail.title":
    "Não foi possível fazer sua solicitação",
  "steps.tripschedule.overview.fail.title":
    "Não foi possivel obter informações do Atendimento",
  "steps.tripschedule.overview.rider.greetings": "Olá, ",
  "steps.tripschedule.overview.trip.status": "Sua viagem está",
  "steps.tripschedule.overview.trip.planned": "agendada",
  "steps.tripschedule.overview.trip.on_going": "em andamento",
  "steps.tripschedule.overview.trip.ended": "finalizada",
  "steps.tripschedule.overview.trip.embark": "Embarque",
  "steps.tripschedule.overview.trip.destination": "Destino",
  "steps.tripschedule.overview.trip.scheduled_date": "Trajeto em ",
  "steps.tripschedule.overview.trip.solicitation_number": "Atendimento",
  "steps.tripschedule.overview.trip.last_checked_at": "Atualizado às ",
  "steps.tripschedule.overview.trip.see_on_map": "Ver no mapa",
  "steps.tripschedule.overview.trip.your_driver": "Seu motorista",
  "steps.tripschedule.onmap.navbar.status.scheduled": "Agendado",
  "steps.tripschedule.onmap.navbar.title": "ATENDIMENTO",
  "steps.tripschedule.onmap.driver.phone": "Telefone",
  "steps.tripschedule.onmap.driver.phone.copied":
    "Telefone copiado para área de transferência",
  "steps.tripschedule.onmap.driver.phone.clicktocopy":
    "Clique para copiar telefone",
  "steps.tripschedule.onmap.navbar.status.on_going": "Em andamento",
  "steps.tripschedule.onmap.navbar.status.planned": "Agendado",
  "steps.tripschedule.onmap.navbar.status.ended": "Realizado",
  "steps.tripschedule.onmap.alerts.fail.refresh": "Falha na localização",
  "steps.tripschedule.onmap.alerts.fail.location":
    "Não foi possível obter a localização em tempo real.",
  "steps.tripschedule.onmap.alerts.fail.token":
    "Não foi possível obter a localização em tempo real (auth).",
  "buttons.start": "Começar",
  "buttons.continue": "Continuar",
  "buttons.tryagain": "Tentar novamente",
  "errors.InvalidQRCodeRequestException":
    "Seu cadastro não foi encontrado. Verifique seus dados e tente novamente.",
  "errors.UnknownException": "Erro desconhecido",
  "errors.ServerException": "Erro de comunicação",
  "errors.InvalidCompanyCodeException": "Empresa não encontrada",
  "errors.RiderCodeAlreadyExists":
    "Já existe um passageiro cadastrado com seus dados",
  "errors.InvalidRegistrationRequestException": "Seus dados não são válidos",
  "errors.RequestAlreadyExistsException":
    "Você já possui uma solicitação pendente",
  "code_type.EMPLOYEE_CODE": "matrícula",
  "code_type.USER_CODE": "código do usuário",
  "code_type.PHONE": "telefone",
  "seat.login.title": "Reserva de Assento",
  "seat.login.input.company": "Código da empresa",
  "seat.login.input.internalCode": "Código do usuário",
  "seat.login.button": "Entrar",
  "seat.login.changeLanguage": "Change language",
  "seat.login.needHelp.pre": "Precisa de ajuda?",
  "seat.login.needHelp.link": "Toque aqui para suporte.",
  "seat.login.error": "Código da empresa ou código do usuário inválido.",
  "seat.login.warning": "Digite os dados corretamente.",
  "seat.profile.title": "Perfil",
  "seat.profile.logout": "Sair",
  "seat.profile.language": "Idioma",
  "seat.profile.language.change": "(trocar)",
  "seat.languageSelector.title": "Idioma",
  "seat.languageSelector.instruction": "Escolha um idioma",
  "seat.home.error.pre": "Algo de errado aconteceu. Tente novamente ou ",
  "seat.home.error.link": "toque aqui e entre em contato com o suporte",
  "seat.home.wait": "Aguarde",
  "seat.home.pickup": "Embarque",
  "seat.home.departure": "Saída",
  "seat.home.dropoff": "Desembarque",
  "seat.home.nextWindowOpensAt":
    "As reservas para próxima semana serão liberadas a partir de ",
  "seat.home.close": "Fechar",
  "seat.home.customerSupportLink": "Não está vendo as linhas que precisa?",
  "seat.home.reducedCapacityWarning":
    "Como medida de enfrentamento à COVID-19, estamos operando com ocupação reduzida.",
  "seat.home.search": "Busque pelo nome",
  "seat.component.week.title": "Semana",
  "seat.component.week.to": "até",
  "seat.component.listReservation.available": "Disponível",
  "seat.component.listReservation.reserved": "Reservado",
  "seat.component.listReservation.blocked": "Bloqueado",
  "seat.component.listReservation.empty.1": "Nenhuma rota",
  "seat.component.listReservation.empty.2": "disponível neste dia",
  "seat.component.listReservation.notReserved": "Não reservado",
  "seat.component.listReservation.availableSeats": "assentos disponíveis",
  "seat.component.listReservation.noAvailableSeats":
    "Nenhum assento disponível",
  "seat.component.listReservation.reservationUntil": "Reserve até {0} de hoje",
  "seat.component.listReservation.reservationUntil.past": "Encerrado às {0}",
  "seat.component.listReservation.reservationAvailable":
    "Disponível para reserva de {0} às {1}",
  "seat.component.listReservation.reservationAvailableDate":
    "Disponível para reserva em {0}",
  "seat.component.modal.error.title":
    "Parece que os assentos acabaram de esgotar para",
  "seat.component.modal.error.text":
    "Se você ainda precisar de transporte nesta data, entre em contato com o Operador Logístico que atende a sua região para uma possível conciliação.",
  "seat.component.modal.close": "Fechar",
  "seat.component.modal.date.1": "em",
  "seat.component.modal.date.2": " de",
  "seat.component.modal.date": "em",
  "seat.component.modal.confirm.title": "Confirmar reserva para",
  "seat.component.modal.confirm.action": "Sim, reservar",
  "seat.component.modal.cancel": "Cancelar",
  "seat.component.modal.cancel.title": "Confirmar o cancelamento para",
  "seat.component.modal.cancel.text": "Sim, cancelar",
  "seat.component.modal.quit.text": "Sim, desistir",
  "seat.component.modal.cancel.abort": "Não, voltar",
  "seat.faq.empty.title": "Nenhuma questão disponível",
  "seat.faq.empty.body": "Em caso de dúvidas entre contato com sua empresa.",
  "seat.stops.input": "Busque por uma parada",
  "seat.stops.embark": "Seu embarque",
  "seat.stops.address": "Seu endereço",
  "seat.stops.steps.stops": "Paradas",
  "seat.stops.steps.confirmation": "Confirmação",
  "seat.stops.title": "Escolha uma parada",
  "seat.stops.confirmation.title": "Confirme sua reserva",
  "seat.stops.confirmation.confirm": "Confirmar reserva",
  "seat.stops.modal.lastReservation.confirm": "Repetir reserva",
  "seat.stops.modal.lastReservation.cancel": "Escolher outra parada",
  "seat.stops.modal.lastReservation.title": "Última reserva nesta rota",
  "seat.stops.modal.confirm.cancel": "Deseja desistir da reserva?",
  "seat.home.myRoutes": "Ver somente minhas rotas",
  "dyn.trip.IN": "IDA",
  "dyn.trip.OUT": "VOLTA",
};
