export default {
  "app.title": "Transporte de la Empresa",
  "steps.company.verification.checking": "Verificando por",
  "steps.company.verification.fail": "No se puede continuar",
  "steps.rider.name.title": "¿Cuál es tu nombre?",
  "steps.rider.name.placeholder": "Nombre y apellido",
  "steps.rider.email.title": "¿Cuál es tu correo electrónico corporativo?",
  "steps.rider.email.placeholder": "yo@miempresa.com.br",
  "steps.rider.code.title": "Ingresa tu",
  "steps.rider.verification.checking.title": "Solicitando acceso para",
  "steps.rider.verification.fail.title": "No se pudo realizar tu solicitud",
  "steps.tripschedule.overview.fail.title":
    "No se pudieron obtener los datos del Atendimento",
  "steps.tripschedule.overview.rider.greetings": "Hola, ",
  "steps.tripschedule.overview.trip.status": "Tu viaje está",
  "steps.tripschedule.overview.trip.planned": "agendado",
  "steps.tripschedule.overview.trip.on_going": "en curso",
  "steps.tripschedule.overview.trip.ended": "finalizado",
  "steps.tripschedule.overview.trip.embark": "Embarque",
  "steps.tripschedule.overview.trip.destination": "Destino",
  "steps.tripschedule.overview.trip.scheduled_date": "Ruta en ",
  "steps.tripschedule.overview.trip.solicitation_number": "Atendimento",
  "steps.tripschedule.overview.trip.last_checked_at": "Actualizado a las ",
  "steps.tripschedule.overview.trip.see_on_map": "Ver en el mapa",
  "steps.tripschedule.overview.trip.your_driver": "Tu conductor",
  "steps.tripschedule.onmap.navbar.status.scheduled": "Agendado",
  "steps.tripschedule.onmap.navbar.title": "ATENDIMENTO",
  "steps.tripschedule.onmap.driver.phone": "Teléfono",
  "steps.tripschedule.onmap.driver.phone.copied":
    "Teléfono copiado al portapapeles",
  "steps.tripschedule.onmap.driver.phone.clicktocopy":
    "Haz clic para copiar el teléfono",
  "steps.tripschedule.onmap.navbar.status.on_going": "En curso",
  "steps.tripschedule.onmap.navbar.status.planned": "Agendado",
  "steps.tripschedule.onmap.navbar.status.ended": "Realizado",
  "steps.tripschedule.onmap.alerts.fail.refresh":
    "Error al obtener la ubicación",
  "steps.tripschedule.onmap.alerts.fail.location":
    "No se pudo obtener la ubicación en tiempo real.",
  "steps.tripschedule.onmap.alerts.fail.token":
    "No se pudo obtener la ubicación en tiempo real (auth).",
  "buttons.start": "Comenzar",
  "buttons.continue": "Continuar",
  "buttons.tryagain": "Intentar de nuevo",
  "errors.InvalidQRCodeRequestException":
    "No se encontró tu registro. Verifica tus datos e intenta nuevamente.",
  "errors.UnknownException": "Error desconocido",
  "errors.ServerException": "Error de comunicación",
  "errors.InvalidCompanyCodeException": "Empresa no encontrada",
  "errors.RiderCodeAlreadyExists":
    "Ya existe un pasajero registrado con tus datos",
  "errors.InvalidRegistrationRequestException": "Tus datos no son válidos",
  "errors.RequestAlreadyExistsException": "Ya tienes una solicitud pendiente",
  "code_type.EMPLOYEE_CODE": "número de empleado",
  "code_type.USER_CODE": "código de usuario",
  "code_type.PHONE": "teléfono",
  "seat.login.title": "Reserva de Asiento",
  "seat.login.input.company": "Código de la empresa",
  "seat.login.input.internalCode": "Código del usuario",
  "seat.login.button": "Ingresar",
  "seat.login.changeLanguage": "Cambiar idioma",
  "seat.login.needHelp.pre": "¿Necesitas ayuda?",
  "seat.login.needHelp.link": "Haz clic aquí para recibir soporte.",
  "seat.login.error": "Código de la empresa o código del usuario inválidos.",
  "seat.login.warning": "Ingresa los datos correctamente.",
  "seat.profile.title": "Perfil",
  "seat.profile.logout": "Cerrar sesión",
  "seat.profile.language": "Idioma",
  "seat.profile.language.change": "(cambiar)",
  "seat.languageSelector.title": "Idioma",
  "seat.languageSelector.instruction": "Elige un idioma",
  "seat.home.error.pre": "Ha ocurrido un error. Inténtalo de nuevo o ",
  "seat.home.error.link": "haz clic aquí para comunicarte con el soporte",
  "seat.home.wait": "Espera",
  "seat.home.pickup": "Embarco",
  "seat.home.departure": "Salida",
  "seat.home.dropoff": "Desembarco",
  "seat.home.nextWindowOpensAt":
    "Las reservas para la próxima semana se abrirán a partir de ",
  "seat.home.close": "Cerrar",
  "seat.home.customerSupportLink": "¿No ves las líneas que necesitas?",
  "seat.home.reducedCapacityWarning":
    "Como medida para enfrentar el COVID-19, estamos operando con capacidad reducida.",
  "seat.home.search": "Buscar por nombre",
  "seat.component.week.title": "Semana",
  "seat.component.week.to": "hasta",
  "seat.component.listReservation.available": "Disponible",
  "seat.component.listReservation.reserved": "Reservado",
  "seat.component.listReservation.blocked": "Bloqueado",
  "seat.component.listReservation.empty.1": "No hay rutas",
  "seat.component.listReservation.empty.2": "disponibles en este día",
  "seat.component.listReservation.notReserved": "No reservado",
  "seat.component.listReservation.availableSeats": "asientos disponibles",
  "seat.component.listReservation.noAvailableSeats":
    "No hay asientos disponibles",
  "seat.component.listReservation.reservationUntil": "Reserva hasta {0} de hoy",
  "seat.component.listReservation.reservationUntil.past": "Cerrado a las {0}",
  "seat.component.listReservation.reservationAvailable":
    "Disponible para reserva de {0} a {1}",
  "seat.component.listReservation.reservationAvailableDate":
    "Disponible para reservar el {0}",
  "seat.component.modal.error.title":
    "Parece que los asientos se han agotado recientemente para",
  "seat.component.modal.error.text":
    "Si aún necesitas transporte en esta fecha, comunícate con el Operador Logístico que atiende tu área para una posible solución.",
  "seat.component.modal.close": "Cerrar",
  "seat.component.modal.date.1": "en",
  "seat.component.modal.date.2": " de",
  "seat.component.modal.date": "en",
  "seat.component.modal.confirm.title": "Confirmar reserva para",
  "seat.component.modal.confirm.action": "Sí, reservar",
  "seat.component.modal.cancel": "Cancelar",
  "seat.component.modal.cancel.title": "Confirmar cancelación para",
  "seat.component.modal.cancel.text": "Sí, cancelar",
  "seat.component.modal.quit.text": "Sim, ríndete",
  "seat.component.modal.cancel.abort": "No, volver",
  "seat.faq.empty.title": "No hay preguntas disponibles",
  "seat.faq.empty.body":
    "Si tienes alguna pregunta, comunícate con tu empresa.",
  "seat.stops.input": "Buscar parada",
  "seat.stops.embark": "Tu embarque",
  "seat.stops.address": "Tu dirección",
  "seat.stops.steps.stops": "Paradas",
  "seat.stops.steps.confirmation": "Confirmación",
  "seat.stops.title": "Elige una parada",
  "seat.stops.confirmation.title": "Confirma tu reserva",
  "seat.stops.confirmation.confirm": "Confirmar reserva",
  "seat.stops.modal.lastReservation.confirm": "Repetir reserva",
  "seat.stops.modal.lastReservation.cancel": "Elige otra parada",
  "seat.stops.modal.lastReservation.title": "Última reserva en esta ruta",
  "seat.stops.modal.confirm.cancel":
    "Al continuar, la reserva no se completará",
  "seat.home.myRoutes": "Ver solo mis rutas",
  "dyn.trip.IN": "IDA",
  "dyn.trip.OUT": "VUELTA",
};
